import API from 'src/apis';
import Cookies from 'js-cookie';
import Profile from 'src/models/response/Profile/index';
import Resource from 'src/models/response/Resource';
// eslint-disable-next-line import/no-cycle
import Event from 'src/models/response/Event';
import EventJoined from 'src/models/response/EventJoined';
import User from 'src/models/response/User';
import Belong from 'src/models/response/Profile/Belong';
import Quota from 'src/models/response/Quota';

export default class UserService {
  static async getProfile() {
    const { data } = await API.user.profile();
    return Profile.fromRes(data);
  }

  static async getDetail() {
    if (this.isLogin) {
      const { data } = await API.user.detail();
      return Profile.fromRes(data);
    }
    return null;
  }

  static async getDetailById(id) {
    const { data } = await API.user.detailById(id);
    return Profile.fromRes(data);
  }

  static get isLogin() {
    return !!Cookies.get('auth');
  }

  static async login(email, password) {
    const res = await API.user.login(email, password);
    return res;
  }

  static async logout() {
    try {
      return await API.user.logout();
    } catch (err) {
      return err.response;
    } finally {
      Cookies.remove('auth');
    }
  }

  static async exists(email) {
    try {
      return await API.user.exists(email);
    } catch (err) {
      return err.response;
    }
  }

  static async signUp(type, email, password) {
    return API.user.signUp(type, email, password);
  }

  static async sendAuthEmail(email) {
    try {
      return await API.user.sendAuthEmail(email);
    } catch (err) {
      return err.response;
    }
  }

  static async sendAuthPhone(phone) {
    return API.user.sendAuthPhone(phone);
  }

  static async authPhone(code) {
    return API.user.authPhone(code);
  }

  static async updateProfile(profile) {
    return API.user.updateProfile(profile);
  }

  static async submit() {
    const res = await API.user.submit();
    return res;
  }

  static async sendResetPasswordEmail(email) {
    try {
      return await API.user.sendResetPasswordEmail(email);
    } catch (err) {
      return err.response;
    }
  }

  static async resetPassword(token, password) {
    return API.user.resetPassword(token, password);
  }

  static async changePassword(previous, next) {
    return API.user.changePassword(previous, next);
  }

  static async genFilesPresignedUrls(uid, files) {
    try {
      return await API.user.genFilesPresignedUrls(uid, files);
    } catch (err) {
      return err;
    }
  }

  static async getResourceList(useId, type, params) {
    const { data } = await API.user.resourceList(useId, type, params);
    return {
      list: data.list.map((el) => Resource.fromRes(el)),
      anchor: data.anchor
    };
  }

  static async createdEvents(uid, params, body) {
    const { data } = await API.user.createdEvents(uid, params, body);
    return {
      count: data.count,
      list: data.list.map((d) => Event.fromRes(d)),
      anchor: data.anchor
    };
  }

  static async joinedEvents(useId, params, body) {
    const { data } = await API.user.joinedEvents(useId, params, body);
    return {
      count: data.count,
      list: data.list.map((d) => EventJoined.fromRes(d)),
      anchor: data.anchor
    };
  }

  static async quota() {
    const { data } = await API.user.quota();
    const { quota } = data;
    return quota?.map((q) => Quota.fromRes(q)) ?? [];
  }

  static async suggest({ type, keyword }) { // 搜尋 user (displayName) (用於顯示建議列表)
    const { data } = await API.user.suggest({ type, keyword });
    return data?.list?.map((user) => User.fromRes(user)) ?? [];
  }

  static async members(id) {
    const { data } = await API.user.members(id);
    return data?.list?.map((user) => Belong.fromRes(user)) ?? [];
  }

}
