import { TOPIC_LABEL_TARGET_TYPE } from 'src/constants';
import Event from './Event';
import Resource from './Resource';

export default class TopicLabelGroup {
  id;
  title;
  sort;
  events;
  resources;

  constructor(params) {
    const {
      id,
      title,
      sort,
      events,
      resources
    } = params ?? {};

    this.id = id;
    this.title = title;
    this.sort = sort;
    this.events = events ?? [];
    this.events = events?.map((e) => Event.fromRes(e)) ?? [];
    this.resources = resources?.map((r) => Resource.fromRes(r)) ?? [];
  }

  get targetType() {
    return this.events.length > 0 ? TOPIC_LABEL_TARGET_TYPE.Event : TOPIC_LABEL_TARGET_TYPE.Resource;
  }

  get targetKey() {
    return this.targetType === TOPIC_LABEL_TARGET_TYPE.Event ? 'events' : 'resources';
  }

  static fromRes(data) {
    return new TopicLabelGroup(data);
  }
}
