import React from 'react';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import clsx from 'clsx';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import PropTypes from 'prop-types';

import { TOPIC_LABEL_TARGET_TYPE } from 'src/constants';
import fist from 'src/assets/fist.png';
import withRouter from 'src/components/withRouter';
import EventCard from 'src/components/EventCard';
import ResourceCard from 'src/components/ResourceCard';

import styles from './styles.module.scss';

@observer
class LabelGroups extends React.Component {
  items = [];
  labelGroupsRef;
  @observable labelId = null;

  constructor(props) {
    super(props);
    makeObservable(this);
    this.props = props;
    this.items = props.items;
    this.labelGroupsRef = props.labelGroupsRef;

    runInAction(() => {
      this.labelId = props.items[0]?.id;
    });
  }

  @action onChangedLabelId = (labelId) => {
    this.labelId = labelId;
  };

  @computed get currentLabelGroup() {
    return this.items?.find((item) => item.id === this.labelId);
  }

  renderLabelTitles() {
    return (
      <div className={clsx(styles.labelTitleContainer)}>
        {this.items?.map((item, i) => (
          <div
            key={item.id}
            className={clsx(styles.labelTitle, {
              [styles.active]: this.labelId === item.id
            })}
            onClick={() => this.onChangedLabelId(item.id)}
          >
            {`#${item.title}`}
          </div>
        ))}
      </div>
    );
  }

  renderLabeledContent() {
    const content = (() => {
      switch (this.currentLabelGroup?.targetType) {
        case TOPIC_LABEL_TARGET_TYPE.Event:
          return this.renderEvents();
        case TOPIC_LABEL_TARGET_TYPE.Resource:
          return this.renderResources();
        default:
          return null;
      }
    })();

    return (
      <div className={styles.labeledContentWrapper}>
        <div className={styles.list}>
          {content}
        </div>
      </div>
    );
  }

  renderEvents() {
    return this.currentLabelGroup?.events?.map((item, i) => (
      <EventCard
        className={styles.eventCard}
        key={item.id}
        item={item}
        colorMode="light"
        onClick={() => this.props.router.navigate(`/events/${item.id}`)}
      />
    ));
  }

  renderResources() {
    return this.currentLabelGroup?.resources?.map((item, i) => (
      <ResourceCard
        className={styles.resourceCard}
        key={item.id}
        type={item.type}
        item={item}
        onClick={() => this.props.router.navigate(`/${item.type}/${item.id}`)}
      />
    ));
  }

  render() {
    return (
      <section
        className={styles.labelGroupsSection}
        ref={this.labelGroupsRef}
      >
        <div className={styles.sectionTitleArea}>
          <img src={fist} alt="fist" />
          <h3 className={styles.titlePurple}>
            {t('topics_event_section_title_1')}
          </h3>
          <h3 className={styles.title}>
            {t('topics_event_section_title_2')}
          </h3>
        </div>

        {this.renderLabelTitles()}
        {this.renderLabeledContent()}
      </section>
    );
  }
}
LabelGroups.propTypes = {
  items: PropTypes.array.isRequired,
  labelGroupsRef: PropTypes.object
};

export default withRouter(withTranslation()(LabelGroups));
