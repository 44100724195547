import React from 'react';
import {
  makeObservable, observable, action, runInAction, computed
} from 'mobx';
import { t } from 'i18next';
import ErrorService from 'src/services/errors';
import { shuffle } from 'src/utils';
import TopicService from 'src/services/topic';

export default class TopicsPageViewModel {
  @observable topic = null;
  @observable main = null;
  @observable sub = null;
  @observable title = null;
  @observable events = [];

  @observable currentIndex = 0;
  @observable isFetching = false;

  @observable isDescClamped = false;
  @observable hasClickedDescMore = false;

  @observable demandsSwiperRef = React.createRef();

  @observable isValid = false;

  @observable needPassword = false;
  @observable password = false;
  @observable passwordError = null;

  constructor(props) {
    makeObservable(this);
    this.props = props;

  }

  @computed get keys() {
    return `${this.main}/${this.sub}`;
  }

  @action didMount = async (props) => {
    this.props = props;
    const { params, navigate } = this.props.router;
    this.main = params.main;
    this.sub = params.sub;
    console.log(props.router.params);

    await this.getList();
  };

  @action didUpdate = async (prevProps, props) => {
    const demandsSwiper = this.demandsSwiperRef?.current?.swiper;
    if (demandsSwiper && demandsSwiper.snapIndex !== 0) {
      demandsSwiper.slideTo(0);
    }
  };

  @computed get labelGroups() {
    return this.topic?.labelGroups ?? [];
  }

  willUnmount = (props) => {
    console.log('ActivityPage.willUnmount');
  };

  getList = async () => {
    this.isFetching = true;

    try {
      const res = await TopicService.getList({
        main: this.main,
        sub: this.sub,
        ...this.password && { pin: this.password }
      });

      runInAction(() => {
        this.topic = res;
        this.title = res.title;
        this.events = shuffle(res.events);

        this.needPassword = false;
      });
    } catch (error) {
      console.log(error);
      const { navigate } = this.props.router;

      switch (error.response?.status) {
        case 401:
          ErrorService.onCustomError(
            t('general_error_content_401'),
            null,
            () => navigate(0)
          );
          break;
        case 403:
          console.log('error.response', error.response);
          if (!this.needPassword) {
            runInAction(() => {
              this.needPassword = true;
            });
          } else if (this.password) {
            runInAction(() => {
              this.passwordError = t('topic_password_error');
            });
          }

          if (error.response?.data?.data?.title) {
            this.title = error.response?.data?.data?.title;
          }

          break;
        case 404:
          ErrorService.onCustomError(
            t('general_error_content_404'),
            null,
            () => navigate('/', { replace: true })
          );
          break;
        default:
          ErrorService.onDefaultError(error);
      }

    } finally {
      runInAction(() => {
        this.isFetching = false;
      });
    }
  };

  @computed get nextEvent() {
    return this.events[this.currentIndex + 1] ?? this.events[0];
  }

  @computed get currentEventId() {
    return this.events[this.currentIndex]?.id;
  }

  @computed get currentEvent() {
    return this.events[this.currentIndex];
  }

  @computed get demands() {
    return this.currentEvent?.demands ?? [];
  }

  @computed get demandTypes() {
    const types = {
      service: false,
      funds: false,
      space: false
    };

    Object.keys(types).forEach((type) => {
      if (this.demands.some((d) => d.type === type)) {
        types[type] = true;
      }
    });

    return Object.keys(types).filter((k) => types[k]) ?? [];
  }

  @action onEventSwiperChange = async ({ realIndex, isEnd }) => {
    console.log('on event swiper change', realIndex, this.currentIndex);
    this.currentIndex = realIndex;
    this.resetTextClamp();
  };

  @action checkIsTextClamped = (ref, keyName) => {
    const el = ref.current;
    if (el) {
      this[`is${keyName}Clamped`] = el.scrollHeight > el.clientHeight;
      return;
    }
    this[`is${keyName}Clamped`] = false;
  };

  @action clickDescMore = () => {
    this.hasClickedDescMore = true;
  };

  @action resetTextClamp = () => {
    this.isDescClamped = false;
    this.hasClickedDescMore = false;
  };

  @action onPasswordChange = (e) => {
    this.password = e.target.value;
    this.passwordError = null;
  };

  toEventDetail = (id) => {
    const { navigate } = this.props.router;
    navigate(`/events/${id}`);
  };

  toDemand = (did) => {
    const { navigate } = this.props.router;
    navigate(`/events/${this.currentEventId}/demands/${did}`);
  };

  toUserProfile = () => {
    const { navigate } = this.props.router;
    navigate(`/user/profile/${this.currentEvent?.user?.id}`);
  };

}
