import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import Breadcrumb from 'src/components/Breadcrumb';
import MainLayout from 'src/components/MainLayout';
import Button from 'src/components/Button';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import Icon from 'src/components/Icon';
import MyResourceModal from 'src/components/Modals/MyResourceModal';
import ResourceAddModal from 'src/components/Modals/ResourceAddModal';
import MemberOnlyFeatureModal from 'src/components/Modals/MemberOnlyFeatureModal';
import LinkifyText from 'src/components/LinkifyText';
import ConstantsStore from 'src/stores/constants';
import { RESOURCE_TYPES } from 'src/constants';

// assets
import iconArrowRight from 'src/assets/iconArrowRight.svg';
import handshake from 'src/assets/handshake.png';
import serviceIcon from 'src/assets/advocateAvatar.svg';
import fundsIcon from 'src/assets/resourceMoneyFill.svg';
import spaceIcon from 'src/assets/resourceSpaceFill.svg';
import awardCertificate from 'src/assets/awardCertificate.svg';
import awardCoBranding from 'src/assets/awardCobranding.svg';
import awardTraining from 'src/assets/awardTraining.svg';
import awardFunds from 'src/assets/awardFunds.svg';
import awardService from 'src/assets/awardService.svg';
import awardSpace from 'src/assets/awardSpace.svg';
import awardStrategy from 'src/assets/awardStrategy.svg';
import awardLesson from 'src/assets/awardLesson.svg';


import DemandDetailPageViewModel from './vm';
import styles from './styles.module.scss';


const demandIcons = {
  service: serviceIcon,
  funds: fundsIcon,
  space: spaceIcon
};

const awardIcons = {
  1: awardCertificate,
  2: awardCoBranding,
  3: awardTraining,
  4: awardFunds,
  5: awardService,
  6: awardSpace,
  7: awardStrategy,
  8: awardLesson,
  9: awardCertificate // 其他
};

@observer
class DemandDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.vm = new DemandDetailPageViewModel(this.props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  renderAwardString(award) {
    if (/^#/.test(award)) {
      return award.split('#')[1];
    }
    return ConstantsStore.awardsOptions.find((opt) => opt.value === award)?.label;
  }

  renderAwards(meta) {
    return (
      <div className={styles.awardContainer}>
        <div className={clsx(styles.textNormal, styles.textCenter)}>
          {t('demand_award_title')}
        </div>
        <div className={styles.awards}>
          {meta.awards.map((award) => (
            <div key={award} className={styles.award}>
              <Icon
                size={24}
                svg
              >
                {awardIcons[award] ?? awardIcons[1]}
              </Icon>
              <div className={clsx(styles.textBold, styles.purple)}>
                {this.renderAwardString(award)}
              </div>
            </div>
          ))}
        </div>
        <ul>
          {meta.awardDescriptions.map((d) => (
            <li key={d} className={styles.dottedBulletItem}>
              {d}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  renderRules(meta) {
    return (
      <>
        <div className={styles.subtitle}>
          {t('demand_rule')}
        </div>
        <ul>
          {meta.rules.map((item) => (
            <li key={item} className={styles.dottedBulletItem}>
              {item}
            </li>
          ))}
        </ul>
      </>
    );
  }

  renderContent() {
    const demand = this.vm.demand;
    const meta = demand.meta;

    switch (this.vm.type) {
      case RESOURCE_TYPES.Service:
        return (
          <>
            <img
              className={clsx(styles.demandIcon, styles.service)}
              src={demandIcons[demand.type]}
              alt="demand"
            />
            <div className={styles.tag}>
              {ConstantsStore.getServiceTypeTag(meta.type)}
            </div>
            <div className={styles.title}>
              {demand.name}
            </div>
            <div className={clsx(styles.desc, styles.topDesc)}>
              {demand.description}
            </div>
            <hr className="dashedPurple" style={{ margin: '15px 0' }} />
            <div className={styles.subtitle}>
              {t('demand_service_type')}
            </div>
            <div className={styles.textBold}>
              {ConstantsStore.serviceTypesOptions?.find((o) => o.children.find((c) => c.value === meta.type))?.children.find((o) => o.value === meta.type)?.label}
            </div>
            <div className={styles.subtitle}>
              {t('demand_service_count')}
            </div>
            <div className={styles.textBold}>
              {meta.count}
              <span>{t('demand_people_unit')}</span>
            </div>
            <div className={styles.subtitle}>
              {t('demand_prefer_profession')}
            </div>
            {meta.jobs.map((j, i) => (
              <span key={j} className={styles.textBold}>
                {ConstantsStore.jobsOptions.find((el) => el.value === j)?.label ?? ''}
                {i < meta.jobs.length - 1 && (
                  <span className={styles.dot}>•</span>
                )}
              </span>
            ))}
            <div className={styles.subtitle}>
              {t('demand_prefer_seniority')}
            </div>
            <div className={styles.textBold}>
              {ConstantsStore.seniorityOptions.find((o) => o.value.toString() === meta.seniority?.toString())?.label ?? meta.seniority}
            </div>
            <div className={styles.subtitle}>
              {t('demand_collab_regions')}
            </div>
            {meta.regions.map((r, i) => (
              <span key={r} className={styles.textBold}>
                {ConstantsStore.getCityLabel(r)}
                {i < meta.regions.length - 1 && (
                  <span className={styles.dot}>•</span>
                )}
              </span>
            ))}
            <div className={styles.subtitle}>
              {t('demand_date')}
            </div>
            <ul>
              {meta.dates.map((d) => (
                <li key={d} className={styles.dottedBulletItem}>
                  {d}
                </li>
              ))}
            </ul>

            <img
              src={handshake}
              alt="handshake"
              className={clsx(styles.illustration, styles.marginTop10)}
            />
            <div className={clsx(styles.slogan)}>
              {t('demand_detail_slogan')}
            </div>

            {meta.awards && this.renderAwards(meta)}

            <div className={styles.decLine} />
            <div className={clsx(styles.textBold, styles.purple, styles.textCenter)}>
              {t('demand_other_details')}
            </div>

            <div className={styles.subtitle}>
              {t('demand_content')}
            </div>
            <ul className={styles.textNormal}>
              {meta.keyItemDescriptions.map((item) => (
                <li key={item} className={styles.dottedBulletItem}>
                  {item}
                </li>
              ))}
            </ul>
            {meta.rules && this.renderRules(meta)}
          </>
        );

      case RESOURCE_TYPES.Funds:
        return (
          <>
            <div className={clsx(styles.demandIcon, styles.other)}>
              <img
                src={demandIcons[demand.type]}
                alt="demand"
              />
            </div>
            <div className={styles.tag}>
              <span className={styles.mark}>$</span>
              {meta.quota}
            </div>
            <div className={styles.title}>
              {demand.name}
            </div>
            <div className={clsx(styles.desc, styles.topDesc)}>
              {demand.description}
            </div>
            <hr className="dashedPurple" style={{ margin: '15px 0' }} />
            <div className={styles.subtitle}>
              {t('demand_funds_source')}
            </div>
            <div className={styles.textBold}>
              {meta.types.map((ft, i) => (
                <span key={ft} className={styles.textBold}>
                  {ConstantsStore.fundsTypesOptions.find((o) => o.value === ft)?.label ?? ''}
                  {i < meta.types.length - 1 && (
                    <span className={styles.dot}>•</span>
                  )}
                </span>
              ))}
            </div>
            <div className={styles.subtitle}>
              {t('demand_regions')}
            </div>
            {meta.regions.map((r, i) => (
              <span key={r} className={styles.textBold}>
                {ConstantsStore.getCityLabel(r)}
                {i < meta.regions.length - 1 && (
                  <span className={styles.dot}>•</span>
                )}
              </span>
            ))}
            <div className={styles.subtitle}>
              {t('demand_funds_purpose')}
            </div>
            <div className={styles.textNormal}>
              {demand.description}
            </div>
            <div className={styles.subtitle}>
              {t('demand_date')}
            </div>
            <ul>
              {meta.dates.map((d) => (
                <li key={d} className={styles.dottedBulletItem}>
                  {d}
                </li>
              ))}
            </ul>
            <img
              src={handshake}
              alt="handshake"
              className={clsx(styles.illustration, styles.marginTop10)}
            />
            <div className={clsx(styles.slogan)}>
              {t('demand_detail_slogan')}
            </div>

            {meta.awards && this.renderAwards(meta)}

            <div className={styles.decLine} />
            <div className={clsx(styles.textBold, styles.purple, styles.textCenter)}>
              {t('demand_other_details')}
            </div>
            <div className={styles.subtitle}>
              {t('demand_content')}
            </div>
            <ul className={styles.textNormal}>
              {meta.keyItemDescriptions.map((item) => (
                <li key={item} className={styles.dottedBulletItem}>
                  {item}
                </li>
              ))}
            </ul>
            {meta.rules && this.renderRules(meta)}
          </>
        );

      case RESOURCE_TYPES.Space:
        return (
          <>
            <div className={clsx(styles.demandIcon, styles.other)}>
              <img
                src={demandIcons[demand.type]}
                alt="demand"
              />
            </div>
            <div className={styles.tag}>
              {meta.capacity}
              <span>{t('demand_people_unit')}</span>
            </div>
            <div className={styles.title}>
              {demand.name}
            </div>
            <div className={clsx(styles.desc, styles.topDesc)}>
              {demand.description}
            </div>
            <hr className="dashedPurple" style={{ margin: '15px 0' }} />
            <div className={styles.subtitle}>
              {t('demand_regions')}
            </div>
            {meta.regions.map((r, i) => (
              <span key={r} className={styles.textBold}>
                {ConstantsStore.getCityLabel(r)}
                {i < meta.regions.length - 1 && (
                  <span className={styles.dot}>•</span>
                )}
              </span>
            ))}
            <div className={styles.subtitle}>
              {t('demand_space_size')}
            </div>
            <div className={styles.textBold}>
              {meta.size}
              <span>{t('demand_space_size_unit')}</span>
            </div>
            <div className={styles.subtitle}>
              {t('demand_purpose')}
            </div>
            {meta.usages.map((value, i) => (
              <span key={value} className={styles.textBold}>
                {ConstantsStore.usagesOptions.find((el) => el.value === value)?.label ?? ''}
                {i < meta.usages.length - 1 && (
                  <span className={styles.dot}>•</span>
                )}
              </span>
            ))}
            <div className={styles.subtitle}>
              {t('demand_equipments')}
            </div>
            {meta.equipments.map((e, i) => (
              <span key={e} className={styles.textBold}>
                {ConstantsStore.equipmentsOptions.find((item) => item.value === e)?.label}
                {i < meta.equipments.length - 1 && (
                  <span className={styles.dot}>•</span>
                )}
              </span>
            ))}

            <div className={styles.subtitle}>
              {t('demand_date')}
            </div>
            <ul>
              {meta.dates.map((d) => (
                <li key={d} className={styles.dottedBulletItem}>
                  {d}
                </li>
              ))}
            </ul>

            <img
              src={handshake}
              alt="handshake"
              className={clsx(styles.illustration, styles.marginTop10)}
            />
            <div className={clsx(styles.slogan)}>
              {t('demand_detail_slogan')}
            </div>

            {meta.awards && this.renderAwards(meta)}

            <div className={styles.decLine} />
            <div className={clsx(styles.textBold, styles.purple, styles.textCenter)}>
              {t('demand_other_details')}
            </div>

            <div className={styles.subtitle}>
              {t('demand_content')}
            </div>
            <ul className={styles.textNormal}>
              {meta.keyItemDescriptions.map((item) => (
                <li key={item} className={styles.dottedBulletItem}>
                  {item}
                </li>
              ))}
            </ul>
            {meta.rules && this.renderRules(meta)}
          </>
        );

      default:
        return null;
    }
  }

  render() {
    return (
      <div className={clsx(styles.demandDetailPage, 'page')}>
        <MainLayout
          {...this.props}
          hideHeader
          footerStyle={{ marginBottom: 80 }}
        >
          <div className={styles.background} />
          <Breadcrumb
            routes={[
              { label: t('demand_list'), path: `/events/${this.vm.eid}/demands` },
              { label: t(`demand_${this.vm.type ?? 'service'}`), path: '' }
            ]}
            right={null}
            colorType="purple"
          />
          <LinkifyText>
            <div className={styles.mainContainer}>
              <div className={styles.contentBox}>
                {this.vm.demand && this.renderContent()}
              </div>
            </div>
            {this.vm.event && (
              <Button
                className={clsx(styles.signupButton, this.vm.isButtonDisabled && styles.disabled)}
                onClick={this.vm.onClickRegister}
                disabled={this.vm.isButtonDisabled}
              >
                <div className={styles.signupBtn}>
                  {this.vm.buttonString}
                  {!this.vm.isButtonDisabled && (
                    <Icon
                      size={20}
                      svg
                      style={{ marginLeft: 5 }}
                    >
                      {iconArrowRight}
                    </Icon>
                  )}
                </div>
              </Button>
            )}

            {this.vm.myResourcesModalVM && (
            <MyResourceModal
              vm={this.vm.myResourcesModalVM}
              isMobile={this.props.context.state.isMobile}
              onClose={this.vm.onCloseMyResourcesModal}
            />
            )}

            <ResourceAddModal
              isOpen={this.vm.isAddResourceModalOpen}
              onModalToggle={this.vm.toggleAddResourceModal}
              isMobile={this.props.context.state.isMobile}
              vm={this.vm}
            />

            {this.vm.memberOnlyFeatureModalVM && (
              <MemberOnlyFeatureModal
                vm={this.vm.memberOnlyFeatureModalVM}
                isMobile={this.props.context.state.isMobile}
                centered
              />
            )}
          </LinkifyText>
        </MainLayout>
      </div>
    );
  }
}

DemandDetailPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
};

export default withProfile(withRouter(withTranslation()(DemandDetailPage)), false);
