import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { t } from 'i18next';
import dayjs from 'dayjs';
import { EVENT_FIELD, EVENT_RELEASE_METHOD, KEYWORD_TYPE } from 'src/constants';
import ConfigProvider from 'antd/es/config-provider';
import Checkbox from 'antd/es/checkbox';
import Spin from 'antd/es/spin';
import DatePicker from 'src/components/DatePicker';
import TextInput from 'src/components/TextInput';
import Select from 'src/components/Select';
import TreeSelect from 'src/components/TreeSelect';
import UploadPhoto from 'src/components/UploadPhoto';
import UploadedItem from 'src/components/UploadPhoto/UploadedItem';
import withErrorInput from 'src/components/withErrorInput';
import Tips from 'src/pages/CreateEvent/CreateEventPage/components/Tips';
import StepBase from 'src/pages/CreateEvent/CreateEventPage/components/Step/StepBase';
import styles from '../styles.module.scss';

const ErrorTextInput = withErrorInput(TextInput);
const ErrorSelect = withErrorInput(Select);
const ErrorTreeSelect = withErrorInput(TreeSelect);

@observer
class Submit extends StepBase {
  constructor(props) {
    super();
    this.props = props;
    this.vm = props?.vm;
  }

  renderBanner = () => {
    const { vm } = this.props;
    const event = vm.event;

    return (
      <div className={styles.bannerContainer}>
        {event.banner
          ? (
            <div className={clsx(styles.uploadedItemsContainer, 'uploadedItemsContainer')}>
              <div className={clsx(styles.title, styles.requiredMark)}>
                {t('event_banner')}
              </div>
              <div>
                <UploadedItem
                  item={{
                    name: vm.uploadBannerVM?.fileList[0]?.name || 'banner.jpeg',
                    size: vm.uploadBannerVM?.fileList[0]?.size || '',
                    mimetype: 'image/png',
                    src: event.banner
                  }}
                  onRemoveFile={this.vm.onRemoveBanner}
                />
              </div>
            </div>
          ) : (
            <UploadPhoto
              placeholder={t('event_banner')}
              label={t('event_banner')}
              className={clsx(styles.uploadPhoto, vm.showError && vm.uploadBannerVM.postList?.length === 0 && 'redBorder', 'uploadFullWidth')}
              vm={vm.uploadBannerVM}
              customUpload={vm.onUploadBanner}
              required
              tooltipTitle={t('event_banner_upload_guideline')}
            />
          )}
      </div>
    );
  };

  renderKeywords = () => {
    const { vm } = this.props;
    const event = vm.event;
    const sdgForDisplay = event.keywordsObj[KEYWORD_TYPE.Sdg] ? event.keywordsObj[KEYWORD_TYPE.Sdg]?.split('_')[0] : null;

    return (
      <>
        <ErrorSelect
          key="event_keyword_sdg"
          placeholder={t('event_keyword_sdg')}
          onChange={(value) => vm.onChangeKeyword(KEYWORD_TYPE.Sdg, value)}
          options={vm.keywordOptionSdg}
          value={sdgForDisplay}
          required
          showSearch
          onSelect={() => vm.onSave(EVENT_FIELD.Keywords)}
        />

        <ErrorSelect
          key="event_keyword_target"
          placeholder={t('event_keyword_target')}
          onChange={(value) => vm.onChangeKeyword(KEYWORD_TYPE.Target, value)}
          options={vm.keywordOptionTarget}
          value={event.keywordsObj[KEYWORD_TYPE.Target]}
          required
          showSearch
          onSelect={() => vm.onSave(EVENT_FIELD.Keywords)}
        />

        <ErrorSelect
          key="event_keyword_keyItem"
          placeholder={t('event_keyword_keyItem')}
          onChange={(value) => vm.onChangeKeyword(KEYWORD_TYPE.KeyItem, value)}
          options={vm.keywordOptionKeyItem}
          value={event.keywordsObj[KEYWORD_TYPE.KeyItem]}
          required
          showSearch
          onSelect={() => vm.onSave(EVENT_FIELD.Keywords)}
        />

        <ErrorTextInput
          key="event_keyword_custom"
          label={t('event_keyword_custom')}
          onChange={(e) => vm.onChangeKeyword(KEYWORD_TYPE.Custom, e.target.value.trim())}
          value={event.keywordsObj[KEYWORD_TYPE.Custom] ? event.keywordsObj[KEYWORD_TYPE.Custom] : ''}
          required
          onBlur={() => vm.onSave(EVENT_FIELD.Keywords)}
          limit={10}
          showError={!this.vm.isCustomKeywordValid}
          validValue={this.vm.isCustomKeywordValid}
          errorMessage={t('error_keyword_format')}
        />
      </>
    );
  };

  renderRelatedUsers = () => {
    const { vm } = this.props;

    const keys = [EVENT_FIELD.CoInitiators, EVENT_FIELD.Collaborators, EVENT_FIELD.CoOrganiser];

    return keys?.map((key) => (
      <ErrorSelect
        key={key}
        placeholder={t(`event_${key}`)}
        mode="tags"
        filterOption={false}
        onSearch={vm.onUserSearch}
        notFoundContent={vm.isFetching ? <Spin size="small" /> : null}
        onChange={(value, option) => vm.onChangeRelatedUsers(key, value, option)}
        options={vm.userOptions}
        defaultValue={vm[key]}
        showLabel={vm[key].length !== 0}
        required={key === EVENT_FIELD.CoInitiators}
        onDropdownVisibleChange={vm.resetUserOptions}
      />
    ));
  };

  renderRegions = () => {
    const { vm } = this.props;

    return (
      <ErrorTreeSelect
        key="event_regions"
        placeholder={t('event_regions')}
        type="regions"
        showSearch
        vm={vm.regionsSelectVM}
        value={vm.regionsSelectVM.selectedItems}
        required
        onSelect={() => vm.onSave(EVENT_FIELD.Regions)}
      />
    );
  };

  renderPrivacy = () => {
    const { vm } = this.props;
    const event = vm.event;
    const options = [
      { label: t('privacy_listed'), value: 'listed' },
      { label: t('privacy_unlisted'), value: 'unlisted' }
    ];

    return (
      <ErrorSelect
        key="event_privacy"
        placeholder={t('event_privacy')}
        onChange={(value) => event.onChange(EVENT_FIELD.Privacy, value)}
        options={options}
        value={event[EVENT_FIELD.Privacy]}
        required
        onSelect={() => vm.onSave(EVENT_FIELD.Privacy)}
      />
    );
  };

  renderTime = () => {
    const event = this.vm.event;
    // NOTE: endAt 最晚到 2025/4/22

    const disabledDatesForRelease = (current) => {
      const limit = dayjs('2025-04-22').startOf('day');
      if (this.vm.forRelease.endAt) {
        return current && (current < dayjs().endOf('day') || current > this.vm.forRelease.endAt.startOf('day') || current > limit);
      }
      return current && (current < dayjs().endOf('day') || current > limit);
    };

    const disabledDatesForEnd = (current) => {
      const end = this.vm.forRelease.releaseAt || dayjs().endOf('day');
      const limit = dayjs('2025-04-23').startOf('day');
      if (this.vm.forRelease.method === EVENT_RELEASE_METHOD.Scheduled) {
        return current && (current < end.endOf('day') || current > limit);
      }

      return current && (current < dayjs().endOf('day') || current > limit);
    };

    return (
      <>
        <div className={styles.marginBottom20}>
          {t('create_event_recruit_period')}
        </div>
        <div>
          {t('create_event_recruit_period_desc')}
        </div>

        <div className={styles.releaseMethods}>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#414EE1'
              }
            }}
          >
            <div className={clsx(styles.releaseTimeOption, styles.auto)}>
              <Checkbox
                key="auto"
                onChange={() => this.vm.onChangeForRelease('method', EVENT_RELEASE_METHOD.Auto)}
                checked={this.vm.forRelease.method === EVENT_RELEASE_METHOD.Auto}
                disabled={!event?.canSubmit}
              >
                {t('create_event_release_method_auto')}
              </Checkbox>

              <DatePicker
                key="event_end_date"
                label={t('event_end_date')}
                placeholder={t('event_end_date')}
                onChange={(date, dateString) => {
                  this.vm.onChangeForRelease('endAt', date);
                }}
                style={{ width: '100%' }}
                required
                value={this.vm.forRelease.endAt}
                disabled={this.vm.forRelease.method !== EVENT_RELEASE_METHOD.Auto || !event?.canSubmit}
                disabledDate={disabledDatesForEnd}
              />
            </div>

            <div className={styles.releaseTimeOption}>
              <Checkbox
                key="manual"
                onChange={() => this.vm.onChangeForRelease('method', EVENT_RELEASE_METHOD.Manual)}
                checked={this.vm.forRelease.method === EVENT_RELEASE_METHOD.Manual}
                disabled={!event?.canSubmit}
              >
                {t('create_event_release_method_manual')}
              </Checkbox>
            </div>

            <div className={clsx(styles.releaseTimeOption, styles.col)}>
              <Checkbox
                key="scheduled"
                onChange={() => this.vm.onChangeForRelease('method', EVENT_RELEASE_METHOD.Scheduled)}
                checked={this.vm.forRelease.method === EVENT_RELEASE_METHOD.Scheduled}
                disabled={!event?.canSubmit}
              >
                {t('create_event_release_method_scheduled')}
              </Checkbox>

              <div className={styles.datesOptions}>
                <DatePicker
                  key="event_start_date"
                  label={t('event_start_date')}
                  placeholder={t('event_start_date')}
                  onChange={(date, dateString) => {
                    this.vm.onChangeForRelease('releaseAt', date);
                  }}
                  style={{ width: '100%' }}
                  required
                  value={this.vm.forRelease.releaseAt}
                  disabled={this.vm.forRelease.method !== EVENT_RELEASE_METHOD.Scheduled || !event?.canSubmit}
                  disabledDate={disabledDatesForRelease}
                />

                <DatePicker
                  key="event_end_date"
                  label={t('event_end_date')}
                  placeholder={t('event_end_date')}
                  onChange={(date, dateString) => {
                    this.vm.onChangeForRelease('endAt', date);
                  }}
                  style={{ width: '100%' }}
                  required
                  value={this.vm.forRelease.endAt}
                  disabled={this.vm.forRelease.method !== EVENT_RELEASE_METHOD.Scheduled || !event?.canSubmit}
                  disabledDate={disabledDatesForEnd}
                />
              </div>
            </div>
          </ConfigProvider>
        </div>
      </>
    );
  };

  renderContent = () => {
    const { vm } = this.props;
    const event = vm.event;
    return (
      <section className={styles.stepSection}>
        <div className={styles.stepMainContent}>
          <ErrorTextInput
            key="event_description"
            label={t('event_description')}
            onChange={(e) => event.onChange('description', e.target.value)}
            value={event.description ?? ''}
            required
            limit={400}
            onBlur={() => vm.onSave('description')}
            multiline
          />

          {this.renderKeywords()}

          {this.renderBanner()}

          {this.renderRelatedUsers()}

          {vm.regionsSelectVM && this.renderRegions()}

          {this.renderPrivacy()}

          {this.renderTime()}
        </div>
        <Tips isHidden />
      </section>
    );
  };

  render() {
    const { vm } = this.props;

    if (!vm) return null;

    return vm && this.renderContent();
  }
}

Submit.propTypes = {
  vm: PropTypes.object
};

Submit.defaultProps = {
  vm: {}
};

export default Submit;
