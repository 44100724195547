import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { t } from 'i18next';
import { EVENT_FIELD, EVENT_TIPS } from 'src/constants';
import Constants, { KEY_OF_OTHER } from 'src/stores/constants';
import Button from 'src/components/Button';
import TextInput from 'src/components/TextInput';
import Select from 'src/components/Select';
import Icon from 'src/components/Icon';
import UploadPhoto from 'src/components/UploadPhoto';
import withErrorInput from 'src/components/withErrorInput';
import DeleteItemButton from 'src/pages/CreateEvent/CreateEventPage/components/DeleteItemButton';
import StickyGroupContainer from 'src/pages/CreateEvent/CreateEventPage/components/StickyGroupContainer';
import Tips from 'src/pages/CreateEvent/CreateEventPage/components/Tips';
import StepBase from 'src/pages/CreateEvent/CreateEventPage/components/Step/StepBase';
import iconAdd from 'src/assets/iconAddPurple.svg';
import styles from '../styles.module.scss';

const ErrorTextInput = withErrorInput(TextInput);
const ErrorSelect = withErrorInput(Select);

@observer
class Target extends StepBase {
  constructor(props) {
    super();
    this.props = props;
    this.vm = props?.vm;
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.vm?.removeEmptyItemsWhenUnmount(EVENT_FIELD.Targets);
  }

  renderOneTarget = (item, index) => {
    const {
      vm
    } = this.props;

    const fieldName = EVENT_FIELD.Targets;
    const isSticky = index === 0 && !vm.showPreview;

    return (
      <div
        key={`target_${index}`}
        className={styles.sectionWrapper}
      >
        <section className={styles.stepSection}>
          <StickyGroupContainer
            isSticky={isSticky}
            containerHeight={vm.tipHeight[EVENT_TIPS.TargetTitle]}
            topOffset={vm.titleAreaHeight}
          >
            <div className={styles.itemTitle}>
              {`/ ${t('create_event_targets_title')} 0${index + 1} /`}
            </div>

            <ErrorSelect
              key="targets_option"
              placeholder={t(`event_field_${fieldName}_option`)}
              onChange={(value) => item.onChange(value, 'option')}
              options={Constants.serviceTargetsOptions}
              value={item.optionKey}
              required
              showSearch
              onSelect={() => vm.onSave(fieldName)}
            />

            {item.optionKey === KEY_OF_OTHER.ServiceTargets && (
              <ErrorTextInput
                key="targets_option_other"
                label={t(`event_field_${fieldName}_option_other`)}
                onChange={(e) => item.onChange(e.target.value.trim(), 'optionOther')}
                value={item.optionOther ?? ''}
                required
                limit={10}
                onBlur={() => vm.onSave(fieldName)}
              />
            )}

            <ErrorTextInput
              key="targets_title"
              label={t(`event_field_${fieldName}_title`)}
              onChange={(e) => item.onChange(e.target.value, 'title')}
              value={item.title ?? ''}
              limit={16}
              required
              onBlur={() => vm.onSave(fieldName)}
            />
          </StickyGroupContainer>

          <Tips
            isHidden={index !== 0 || vm.showPreview}
            fieldName={EVENT_TIPS.TargetTitle}
            setTipHeight={vm.setTipHeight}
          />

        </section>

        <section className={styles.stepSection}>
          <StickyGroupContainer
            isSticky={isSticky}
            containerHeight={vm.tipHeight[EVENT_TIPS.TargetDesc]}
            topOffset={vm.titleAreaHeight}
          >
            <ErrorTextInput
              key="targets_description"
              label={t(`event_field_${fieldName}_description`)}
              onChange={(e) => item.onChange(e.target.value, 'description')}
              value={item.description ?? ''}
              limit={152}
              multiline
              required
              onBlur={() => vm.onSave(fieldName)}
            />
          </StickyGroupContainer>

          <Tips
            isHidden={index !== 0 || vm.showPreview}
            fieldName={EVENT_TIPS.TargetDesc}
            setTipHeight={vm.setTipHeight}
          />
        </section>

        <section className={styles.stepSection}>
          <div className={styles.stepMainContent}>
            {item.uploadPhotoVM && (
              <UploadPhoto
                placeholder={t('event_field_photo')}
                label={t('event_field_photo')}
                className={clsx(styles.uploadPhoto, vm.showError && vm.uploadPhotoVM.postList?.length === 0 && 'redBorder', 'uploadFullWidth')}
                vm={item.uploadPhotoVM}
                customUpload={() => vm.onUploadPhoto(item, fieldName)}
              />
            )}

            <ErrorTextInput
              key="target_video"
              label={t('event_field_youtube_link')}
              onChange={(e) => item.onChange(e.target.value, 'video')}
              value={item.video ?? ''}
              showError={!item.isVideoValid}
              validValue={item.isVideoValid}
              errorMessage={t('url_is_not_valid')}
              onBlur={() => vm.onSave(fieldName)}
            />

            {index !== 0 && (
              <DeleteItemButton
                onClick={() => {
                  vm.event.removeItemByIndex(EVENT_FIELD.Targets, index);
                  vm.onSave(fieldName);
                }}
                text={`${t('create_event_targets_title')} 0${index + 1}`}
                generalModalViewModel={vm.generalModalViewModel}
              />
            )}
          </div>

          <Tips isHidden />
        </section>

      </div>
    );
  };

  render() {
    const { vm } = this.props;
    const event = vm.event ?? {};
    const targets = event[EVENT_FIELD.Targets];

    return (
      <>
        {targets?.map((item, i) => this.renderOneTarget(item, i))}

        {targets?.length < 5 && (
          <section className={styles.stepSection}>
            <div className={clsx(styles.stepMainContent, styles.flexCentered)}>
              <Button
                ghost
                onClick={() => event.addEmptyItemInArray(EVENT_FIELD.Targets)}
                className={styles.addItemButton}
              >
                <div className={styles.flexRowCenter}>
                  <Icon
                    size={28}
                    svg
                  >
                    {iconAdd}
                  </Icon>
                  {t('button_add_target')}
                </div>
              </Button>
            </div>
            <Tips isHidden />
          </section>
        )}
      </>
    );
  }
}

Target.propTypes = {
  vm: PropTypes.object
};

Target.defaultProps = {
  vm: {}
};

export default Target;
