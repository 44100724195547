import Linkify from 'linkify-react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const LinkifyText = ({ children, options }) => (
  <Linkify
    options={{
      attributes: {
        className: styles.hyperlink
      },
      target: '_blank',
      rel: 'noreferrer',
      ...options
    }}
  >
    {children}
  </Linkify>
);

export default LinkifyText;

LinkifyText.propTypes = {
  options: PropTypes.object,
  children: PropTypes.node
};

LinkifyText.defaultProps = {
  options: {},
  children: null
};

