import Event from './Event';
import Demand from './Demand';
import TopicLabelGroup from './TopicLabelGroup';

export default class Topic {
  constructor(params) {
    const {
      title,
      description,
      thumbnail,
      events,
      texts,
      images,
      labelGroups
    } = params ?? {};

    this.title = title;
    this.description = description;
    this.thumbnail = thumbnail ?? {};
    this.events = events?.map((e) => {
      const item = Event.fromRes(e);
      item.demands = e.demands?.map((d) => Demand.fromRes(d)) ?? [];
      return item;
    }) ?? [];
    this.texts = texts ?? {};
    this.images = images ?? {};

    this.sponsorLogos = this.deserializeImageArrayAndExtractTexts(/^sponsorLogos/);
    this.specialThanksImgs = this.deserializeImageArrayAndExtractTexts(
      /^specialThanks/,
      [
        { key: 'description', regex: /specialThanksDesc-(\d+)/ },
        { key: 'link', regex: /specialThanksLink-(\d+)/ }
      ]
    );
    this.partnersImgs = this.deserializeImageArrayAndExtractTexts(/^partners/, [{ key: 'link', regex: /partnersLink-(\d+)/ }]);
    this.initiatorsImgs = this.deserializeImageArrayAndExtractTexts(/^initiators/, [{ key: 'link', regex: /initiatorsLink-(\d+)/ }]);

    this.labelGroups = labelGroups?.map((g) => TopicLabelGroup.fromRes(g)) ?? [];
  }

  get thumbnailUrl() {
    return this.thumbnail?.url;
  }

  get specialThanksHasAnyDesc() {
    return this.specialThanksImgs.some((item) => item.description);
  }

  deserializeImageArrayAndExtractTexts = (imageRegex, textRules = []) => {
    const items = Object.entries(this.images)?.filter(([key, obj]) => imageRegex.test(key));
    const imagesArr = items.map((item) => item[1]) ?? [];
    textRules.forEach((rule) => {
      Object.entries(this.texts)?.forEach(([key, value]) => {
        const regex = rule.regex;
        const match = key.match(regex);
        if (match) {
          const index = match[1];
          imagesArr[index][rule.key] = value;
        }
      });
    });

    return imagesArr;
  };

  static fromRes(data) {
    return new Topic(data);
  }
}
