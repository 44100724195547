import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { t } from 'i18next';
import { EVENT_FIELD, EVENT_TIPS } from 'src/constants';
import TextInput from 'src/components/TextInput';
import withErrorInput from 'src/components/withErrorInput';
import StickyGroupContainer from 'src/pages/CreateEvent/CreateEventPage/components/StickyGroupContainer';
import Tips from 'src/pages/CreateEvent/CreateEventPage/components/Tips';
import StepBase from 'src/pages/CreateEvent/CreateEventPage/components/Step/StepBase';
import styles from '../styles.module.scss';

const ErrorTextInput = withErrorInput(TextInput);
@observer
class Basic extends StepBase {
  constructor(props) {
    super();
    this.props = props;
  }

  renderContact() {
    const { vm } = this.props;
    const fieldName = EVENT_FIELD.Contact;
    const contact = vm[fieldName] ?? {};

    return (
      <section className={styles.stepSection}>
        <div className={styles.stepMainContent}>
          <div className={styles.itemTitle}>
            {`/ ${t('create_event_contact_title')} /`}
          </div>

          <ErrorTextInput
            key="contact_name"
            label={t('contact_name')}
            onChange={(e) => vm.onChangeBasic(fieldName, e.target.value, 'name')}
            value={contact.name ?? ''}
            required
            onBlur={() => vm.onSave(fieldName)}
          />

          <ErrorTextInput
            key="contact_jobTitle"
            label={t('contact_jobTitle')}
            onChange={(e) => vm.onChangeBasic(fieldName, e.target.value, 'jobTitle')}
            value={contact.jobTitle ?? ''}
            required
            onBlur={() => vm.onSave(fieldName)}
          />

          <ErrorTextInput
            key="contact_phone"
            label={t('contact_phone')}
            onChange={(e) => vm.onChangeBasic(fieldName, e.target.value, 'phone')}
            value={contact.phone ?? ''}
            required
            onBlur={() => vm.onSave(fieldName)}
            showError={!!contact.phone && !contact.isPhoneValid}
            validValue={contact.isPhoneValid}
            errorMessage={t('format_is_wrong')}
          />

          <ErrorTextInput
            key="contact_email"
            label={t('contact_email')}
            onChange={(e) => vm.onChangeBasic(fieldName, e.target.value, 'email')}
            value={contact.email ?? ''}
            required
            onBlur={() => vm.onSave(fieldName)}
            showError={!!contact.email && !contact.isEmailValid}
            validValue={contact.isEmailValid}
            errorMessage={t('format_is_wrong')}
          />
        </div>
        <Tips isHidden />
      </section>
    );
  }

  renderContent() {
    const {
      vm
    } = this.props;

    const isSticky = !vm.showPreview;

    return (
      <>
        <section className={styles.stepSection}>
          <StickyGroupContainer
            isSticky={isSticky}
            containerHeight={vm.tipHeight[EVENT_TIPS.Name]}
            topOffset={vm.titleAreaHeight}
          >
            <ErrorTextInput
              key={EVENT_FIELD.Name}
              label={t(`event_field_${EVENT_FIELD.Name}`)}
              onChange={(e) => vm.onChangeBasic(EVENT_FIELD.Name, e.target.value)}
              value={vm[EVENT_FIELD.Name] ?? ''}
              limit={35}
              multiline
              required
              onBlur={() => vm.onSave(EVENT_FIELD.Name)}
            />
          </StickyGroupContainer>

          <Tips
            fieldName={EVENT_TIPS.Name}
            isHidden={vm.showPreview}
            setTipHeight={vm.setTipHeight}
          />
        </section>

        <section className={styles.stepSection}>
          <StickyGroupContainer
            isSticky={isSticky}
            containerHeight={vm.tipHeight[EVENT_TIPS.Slogan]}
            topOffset={vm.titleAreaHeight}
          >
            <ErrorTextInput
              key={EVENT_FIELD.Slogan}
              label={t(`event_field_${EVENT_FIELD.Slogan}`)}
              onChange={(e) => vm.onChangeBasic(EVENT_FIELD.Slogan, e.target.value)}
              value={vm[EVENT_FIELD.Slogan] ?? ''}
              limit={25}
              caption={t(`event_field_caption_${EVENT_FIELD.Slogan}`)}
              multiline
              required
              onBlur={() => vm.onSave(EVENT_FIELD.Slogan)}
            />
          </StickyGroupContainer>

          <Tips
            fieldName={EVENT_TIPS.Slogan}
            isHidden={vm.showPreview}
            setTipHeight={vm.setTipHeight}
          />
        </section>

        <section className={styles.stepSection}>
          <div className={styles.stepMainContent}>
            <div className={styles.customIdDesc}>
              {t('create_event_customId_desc')}
            </div>
            <div className={styles.eventUrlContainer}>
              <span className={styles.eventUrl}>
                goaction.5percent-design-action.com/events/
              </span>
              <div className={styles.customIdInputContainer}>
                <ErrorTextInput
                  key={EVENT_FIELD.CustomId}
                  label={t(`event_field_${EVENT_FIELD.CustomId}`)}
                  onChange={(e) => vm.onChangeCustomId(e.target.value)}
                  value={vm[EVENT_FIELD.CustomId] ?? ''}
                  required
                  onBlur={vm.onCustomIdBlur}
                  showError={!!vm.customIdError}
                  validValue={!vm.customIdError}
                  errorMessage={vm.customIdError}
                />
              </div>
            </div>
          </div>
          <Tips isHidden />
        </section>

        {this.renderContact()}
      </>
    );
  }

  render() {
    const {
      vm
    } = this.props;

    return !!vm && this.renderContent();
  }
}

Basic.propTypes = {
  vm: PropTypes.object
};

Basic.defaultProps = {
  vm: {}
};

export default Basic;
